<template>
    <div class="admin-container" >
        <h1>Intervenants</h1>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Titre</th>
                        <th>Prénom</th>
                        <th>Nom</th>
                        <th>Email</th>
                        <th>Biographie</th>
                        <th>Avatar</th>     
                    </tr>
                </thead>
                <tbody>   
                    <tr v-for="(item, idx) in users" :key="idx">
                        <td>{{ item.speaker_title }}</td>
                        <td>{{ item.firstname }}</td>
                        <td>{{ item.lastname }}</td>
                        <td>{{ item.email }}</td>
                        <td v-html="item.speaker_description"></td>
                        <td>
                            <img :src="`${$store.state.URL}avatars/${item.speaker_avatar}`"  alt="avatar" class="avatar"/>
                        </td>
                    </tr>                                   
                </tbody>
            </table>
        </div>
        <NotifError :errorTxt="errorTxt" v-if="errorTxt" v-on:closeModal="errorTxt = null"></NotifError>

    </div>
</template>

<script>
import axios from 'axios'
import store from './../../store'
import NotifError from './../../components/NotifError'

export default {
    name : 'AdminUsers',
    data() {
        return {
            users : null,
            errorTxt : null
        }
    },
    components : {
        NotifError
    },
    mounted() {
        let vm = this
        this.errorTxt = null

        axios
            .get(store.getters.getURL + "user/getSpeakers.php?token=" + this.$store.state.JWT_TOKEN )
            .then(function (response) { 
                if(response.data.state == 'success') {
                    vm.users= response.data.res
                }else {
                    if(response.data.authError) {
                        store.commit('logout')
                        vm.$router.push('/login')
                    }else {
                        vm.errorTxt = 'An error has occured: ' + response.data.retour
                    }
                }
            })
            .catch((err) => {
                this.errorTxt = 'An error has occured:' +  err
            });
    },
   
}
</script>

<style lang="scss" scoped>

    .avatar {
        width:80px;
        height:80px;
    }

    .table-container {
        margin-top: 40px;
    }


</style>